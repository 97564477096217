import mock from "@/@fake-db/mock";
import jwt from "jsonwebtoken";

let storedUserData = null;
let data = {
  users: [],
};

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: "dd5f3089-40c3-403d-af14-d0c228b05cb4",
  refreshTokenSecret: "7c4c1c50-3230-45bf-9eae-c9b2e401c767",
  expireTime: "1440m",
  refreshTokenExpireTime: "1440m",
};

mock.onPost("/jwt/login").reply((request) => {
  const { email, password } = JSON.parse(request.data);

  // re get stored data
  if (localStorage.getItem("userData") != null) {
    // get stored data
    storedUserData = JSON.parse(localStorage.getItem("userData"));
    //
    if (
      storedUserData.fullName !== null &&
      storedUserData.fullName !== undefined
    ) {
      // have data
      data.users.push({
        id: 1,
        sku_corporation: storedUserData.sku_corporation,
        fullName: storedUserData.fullName,
        username: storedUserData.username,
        password: storedUserData.password,
        // eslint-disable-next-line global-require
        avatar: require("@/assets/images/avatars/13-small.png"),
        email: storedUserData.email,
        role: storedUserData.role,
        ability: storedUserData.ability,
        extras: storedUserData.extras,
        special_permissions: storedUserData.special_permissions,
        default_dashboard: storedUserData.default_dashboard,
        permissions: storedUserData.extras,
        departmentData: storedUserData.departmentData,
      });
    }
  }

  let error = {
    email: ["Erreur survenu"],
  };

  const user = data.users.find(
    (u) => u.email === email && u.password === password
  );

  if (user) {
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, {
        expiresIn: jwtConfig.expireTime,
      });
      const refreshToken = jwt.sign(
        { id: user.id },
        jwtConfig.refreshTokenSecret,
        {
          expiresIn: jwtConfig.refreshTokenExpireTime,
        }
      );

      const userData = { ...user };

      delete userData.password;

      const response = {
        userData,
        accessToken,
        refreshToken,
      };

      return [200, response];
    } catch (e) {
      error = e;
    }
  } else {
    error = {
      email: ["Compte/Email ou Mot de passe Incorect"],
    };
  }

  return [400, { error }];
});

mock.onPost("/jwt/register").reply((request) => {
  const { username, email, password } = JSON.parse(request.data);

  // If not any of data is missing return 400
  if (!(username && email && password)) return [400];

  const isEmailAlreadyInUse = data.users.find((user) => user.email === email);
  const isUsernameAlreadyInUse = data.users.find(
    (user) => user.username === username
  );

  const error = {
    password: !password ? ["Entrer votre mot de passe svp"] : null,
    email: (() => {
      if (!email) return ["Entrer votre Compte/Email svp."];
      if (isEmailAlreadyInUse) return ["Compte/Email déjà utilisé."];
      return null;
    })(),
    username: (() => {
      if (!username) return ["Veuillez saisir votre compte."];
      if (isUsernameAlreadyInUse) return ["Ce compte est déjà utilisé."];
      return null;
    })(),
  };

  if (!error.username && !error.email) {
    const userData = {
      email,
      sku_corporation,
      password,
      username,
      fullName: "",
      avatar: null,
      role: "client",
      ability: [
        {
          action: "read",
          subject: "Auth",
        },
      ],
    };

    // Add user id
    const { length } = data.users;
    let lastIndex = 0;
    if (length) {
      lastIndex = data.users[length - 1].id;
    }
    userData.id = lastIndex + 1;

    data.users.push(userData);

    const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, {
      expiresIn: jwtConfig.expireTime,
    });

    const user = { ...userData };
    delete user.password;
    const response = {
      userData: user,
      accessToken,
    };

    return [200, response];
  }
  return [400, { error }];
});

mock.onPost("/jwt/refresh-token").reply((request) => {
  const { refreshToken } = JSON.parse(request.data);

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret);

    const userData = { ...data.users.find((user) => user.id === id) };

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, {
      expiresIn: jwtConfig.expiresIn,
    });
    const newRefreshToken = jwt.sign(
      { id: userData.id },
      jwtConfig.refreshTokenSecret,
      {
        expiresIn: jwtConfig.refreshTokenExpireTime,
      }
    );

    delete userData.password;
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    };

    return [200, response];
  } catch (e) {
    const error = "Token d'actualisation invalide";
    return [401, { error }];
  }
});
