import mock from "@/@fake-db/mock";
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: "DFJ Admin",
    saleToday: "57.6",
  },
  subscribersGained: {
    series: [
      {
        name: "Béneficiaires",
        data: [28, 40, 36, 52, 38, 60, 55],
      },
    ],
    analyticsData: {
      subscribers: 92600,
    },
  },
  ordersRecevied: {
    series: [
      {
        name: "Commandes",
        data: [10, 15, 8, 15, 7, 12, 8],
      },
    ],
    analyticsData: {
      orders: 38400,
    },
  },
  avgSessions: {
    sessions: 2700,
    lastDays: ["28 Derniers Jours", "Mois Passé", "Année Passée"],
    growth: "+5.2%",
    goal: 100000,
    users: 100000,
    retention: 90,
    duration: 1,
    salesBar: {
      series: [
        {
          name: "Sessions",
          data: [75, 125, 225, 175, 125, 75, 25],
        },
      ],
    },
  },
  supportTracker: {
    title: "Demande de support",
    lastDays: ["28 Derniers Jours", "Mois Passé", "Année Passée"],
    totalTicket: 163,
    newTicket: 29,
    openTicket: 63,
    responseTime: 1,
    supportTrackerRadialBar: {
      series: [83],
    },
  },
  timeline: {
    step1: {
      title: "12 Dossiers traités",
      subtitle: "Les dossiers déjà traités par l'organisation.",
      img: require("@/assets/images/icons/json.png"),
      fileName: "data.json",
      duration: "12 min passées",
    },
    step2: {
      title: "Rendevous avec le requerant",
      subtitle: "Project réunion avec OROSEP  @15:00",
      avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
      avatarName: "Ezra (Client)",
      occupation: "M&E de DFJ",
      duration: "15 min déjà passées",
    },
    step3: {
      title: "Créer un nouveau projet",
      subtitle: "Ajouter les fichiers au dossier",
      duration: "2 jrs passés",
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          name: "Mumbere Barthelemy",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          name: "Eliel Kamate",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          name: "Jamel Kausi",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          name: "Elikar Kanane",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          name: "David Mika",
        },
      ],
    },
    step4: {
      title: "Créer un nouveau projet",
      duration: "5 jrs passés",
      subtitle: "Ajouter les fichiers au dossier",
    },
  },
  salesChart: {
    series: [
      {
        name: "Achats",
        data: [90, 50, 86, 40, 100, 20],
      },
      {
        name: "Visites",
        data: [70, 75, 70, 76, 20, 85],
      },
    ],
  },
  appDesign: {
    date: "18 Aout, 22",
    title: "Design de l'Interface",
    subtitle: "Ralativité pour IOS, Android et Web, multi-target Design",
    teams: [
      { name: "Figma", color: "light-warning" },
      { name: "Wireframe", color: "light-primary" },
    ],
    members: [
      {
        img: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
        color: "primary",
      },
      { text: "PI", color: "light-danger" },
      {
        img: require("@/assets/images/portrait/small/avatar-s-14.jpg"),
        color: "primary",
      },
      {
        img: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
        color: "primary",
      },
      { text: "AL", color: "light-secondary" },
    ],
    planing: [
      { title: "Date", subtitle: "19 Aout, 22" },
      { title: "Budget", subtitle: "4951.91$" },
      { title: "Coût", subtitle: "840.99$" },
    ],
  },
};
/* eslint-disable global-require */
mock.onGet("/analytics/data").reply(() => [200, data]);
