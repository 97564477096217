<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () => import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
import navItems from "@/navigation/vertical";

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  mounted() {
    if (this.$router.options.routes.length <= 82) {
      this.AddMenusAndRoutes();
    }
  },
  methods: {
    async AddMenusAndRoutes(mode) {
      // Same as above
      function isset(ref) {
        return typeof ref !== "undefined";
      }

      const dataStored = localStorage.getItem("userData");
      if (dataStored != null && dataStored != undefined) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        // userData exists
        const departmentsPermissions = userData.departmentData;
        var routesFormated = this.$router.options.routes;

        for (let index = 0; index < departmentsPermissions.length; index++) {
          // const
          const department = departmentsPermissions[index];
          const modules = departmentsPermissions[index].modules;
          const departmentRoute = department.route;

          // add menus
          try {
            if (department.route == "human-ressource") {
              // add separator
              navItems.push({
                header: "Modules Principaux",
                action: "read",
                resource: "ACL",
              });
            } else if (department.route == "data-collect") {
              // add separator
              navItems.push({
                header: "Autres Outils",
                action: "read",
                resource: "ACL",
              });
            }

            var modulesFormated = [];
            for (let module_index = 0; module_index < modules.length; module_index++) {
              const module = modules[module_index];
              if (module.module != "") {
                modulesFormated.push({
                  title: module.module,
                  route: module.ressource,
                  // acl: {
                  action: "read",
                  resource: module.ressource,
                  // },
                });
              }
            }

            //
            if (department.name != "") {
              navItems.push({
                title: department.name,
                icon: department.icon,
                // acl: {
                action: "read",
                resource: department.route,
                // },
                // tag: '2',
                tagVariant: "light-warning",
                children: modulesFormated,
              });
            }
          } catch (error) {
            // Only runs when there is an error/exception
          } finally {
            // Code here always runs. Doesn't matter if there was an error or not.
          }

          // add routes
          for (let module_index = 0; module_index < modules.length; module_index++) {
            const module = modules[module_index];

            const departmentName =
              department.name.charAt(0).toLowerCase() +
              department.name.substring(1, department.name.length).toLowerCase();

            const moduleName =
              module.ressource.charAt(0).toUpperCase() +
              module.ressource.substring(1, module.ressource.length).toLowerCase();

            let moduleFolder = "";
            if (module.folder == true) {
              moduleFolder = module.ressource + "/";
            }

            if (module.ressource != null && module.module != null) {
              if (module.folder == true) {
                // check permissions
                if (isset(module.list) && module.list == true) {
                  routesFormated.push(
                    {
                      path: "/" + departmentRoute + "/" + module.ressource,
                      name: module.ressource,
                      component: () =>
                        import(
                          "@/views/" + departmentRoute + "/" + moduleFolder + "Index.vue"
                        ),
                      meta: {
                        resource: module.ressource,
                        pageTitle: module.module,
                        pageIcon: department.icon,
                      },
                    },
                    {
                      path: "/" + departmentRoute + "/" + module.ressource,
                      name: module.ressource + "-list",
                      component: () =>
                        import(
                          "@/views/" + departmentRoute + "/" + moduleFolder + "Index.vue"
                        ),
                      meta: {
                        resource: module.ressource,
                        pageTitle: module.module,
                        pageIcon: department.icon,
                      },
                    }
                  );
                }
                if (isset(module.add) && module.add == true) {
                  routesFormated.push({
                    path:
                      "/" +
                      departmentRoute +
                      "/" +
                      module.ressource +
                      "/" +
                      module.ressource +
                      "-add-new",
                    name: module.ressource + "-add-new",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: department.name,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      //}
                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Ajouter",
                          active: true,
                        },
                      ],
                    },
                  });
                }
                if (isset(module.view) && module.view == true) {
                  routesFormated.push({
                    path: "/" + departmentRoute + "/" + module.ressource + "-view/:id",
                    name: module.ressource + "-view",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: departmentName,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Infos " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });
                }
                if (isset(module.update) && module.update == true) {
                  routesFormated.push({
                    path: "/" + departmentRoute + "/" + module.ressource + "-edit/:id",
                    name: "" + module.ressource + "-edit",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Controller.vue"
                      ),
                    meta: {
                      pageTitle: department.name,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Modifier " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });

                  // check if accounting
                  if (departmentRoute == "accounting") {
                    routesFormated.push({
                      path: "/" + departmentRoute + "/" + module.ressource + "-param/:id",
                      name: "" + module.ressource + "-param",
                      params: { searchResults: "testResult" },
                      component: () =>
                        import(
                          "@/views/" +
                          departmentRoute +
                          "/" +
                          module.ressource +
                          "/Configuration.vue"
                        ),
                      meta: {
                        pageTitle: department.name,
                        pageIcon: department.icon,
                        //{ acl
                        resource: module.ressource,
                        action: "read",
                        //}

                        contentClass: module.ressource,
                        navActiveLink: module.ressource,

                        breadcrumb: [
                          {
                            text: moduleName + "s",
                          },
                          {
                            text: "Parametrer " + moduleName,
                            active: true,
                          },
                        ],
                      },
                    });
                  }
                }
                if (isset(module.delete) && module.delete == true) {
                  // routesFormated.push();
                }
                if (isset(module.monitoring) && module.monitoring == true) {
                  routesFormated.push({
                    path:
                      "/" + departmentRoute + "/" + module.ressource + "-monitoring/:id",
                    name: module.ressource + "-monitoring",
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        module.ressource +
                        "/Monitoring.vue"
                      ),
                    meta: {
                      pageTitle: departmentName,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                      //}

                      contentClass: module.ressource,
                      navActiveLink: module.ressource,

                      breadcrumb: [
                        {
                          text: moduleName + "s",
                        },
                        {
                          text: "Suivi " + moduleName,
                          active: true,
                        },
                      ],
                    },
                  });
                }
                // add action routes end
              } else {
                // module without folder
                if (isset(module.view) && module.view == true) {
                  routesFormated.push({
                    path: "/" + departmentRoute + "/" + module.ressource,
                    name: module.ressource,
                    component: () =>
                      import(
                        "@/views/" +
                        departmentRoute +
                        "/" +
                        moduleFolder +
                        moduleName +
                        ".vue"
                      ),
                    meta: {
                      pageTitle: module.module,
                      pageIcon: department.icon,
                      //{ acl
                      resource: module.ressource,
                      action: "read",
                    },
                  });
                }
              }
            }
          }
        }
        // update routes
        this.$router.addRoutes(routesFormated);
      } else {
        // no userData
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(
          0,
          -2
        )
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
};
</script>
