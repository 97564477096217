const routesFormated = [
  {
    path: "/dashboard/default",
    name: "dashboard-default",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta: {
      resource: "ACL",
    },
  },
  {
    path: "/data-collect/form-designer",
    name: "form-designer",
    component: () => import("@/views/data-collect/Form-static-design.vue"),
    meta: {
      resource: "ACL",
    },
  },
  {
    path: "/data-collect/dynamic-form-designer",
    name: "form-dynamic-designer",
    component: () => import("@/views/data-collect/Form-dynamic-design.vue"),
    meta: {
      resource: "ACL",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "/",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-refresh",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/login-v1",
    name: "auth-login-v1",
    component: () => import("@/views/pages/authentication/Login-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/login-v2",
    name: "auth-login-v2",
    component: () => import("@/views/pages/authentication/Login-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/pages/authentication/Register.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/register-v1",
    name: "auth-register-v1",
    component: () => import("@/views/pages/authentication/Register-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/register-v2",
    name: "auth-register-v2",
    component: () => import("@/views/pages/authentication/Register-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/pages/authentication/forgot-password-v1",
    name: "auth-forgot-password-v1",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/forgot-password-v2",
    name: "auth-forgot-password-v2",
    component: () =>
      import("@/views/pages/authentication/ForgotPassword-v2.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/authentication/reset-password-v1",
    name: "auth-reset-password-v1",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v1.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/authentication/reset-password-v2",
    name: "auth-reset-password-v2",
    component: () =>
      import("@/views/pages/authentication/ResetPassword-v2.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/pages/miscellaneous/ComingSoon.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/pages/miscellaneous/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/miscellaneous/error",
    name: "misc-error",
    component: () => import("@/views/pages/miscellaneous/Error.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/pages/account-setting",
    name: "pages-account-setting",
    component: () => import("@/views/pages/account-setting/AccountSetting.vue"),
    meta: {
      pageTitle: "Account Settings",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    component: () => import("@/views/pages/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Pages",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
    },
  }, 
  {
    path: "/apps/email",
    name: "apps-email",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
    },
  },
  {
    path: "/apps/email/:folder",
    name: "apps-email-folder",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/email/label/:label",
    name: "apps-email-label",
    component: () => import("@/views/apps/email/Email.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "email-application",
      navActiveLink: "apps-email",
    },
    beforeEnter(to, _, next) {
      if (
        ["personal", "company", "important", "private"].includes(
          to.params.label
        )
      )
        next();
      else next({ name: "error-404" });
    },
  },
  {
    path: "/apps/todo",
    name: "apps-todo",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      //{ acl
      action: "read",
      //} 
    },
  },
  {
    path: "/apps/todo/:filter",
    name: "apps-todo-filter",
    component: () => import("@/views/apps/todo/Todo.vue"),
    meta: {
      contentRenderer: "sidebar-left",
      contentClass: "todo-application",
      navActiveLink: "apps-todo",
      //{ acl
      action: "read",
      //} 
    },
    beforeEnter(to, _, next) {
      if (["important", "completed", "deleted"].includes(to.params.filter))
        next();
      else next({ name: "error-404" });
    },
  },

  // /data-collect/dynamic-form-designer   component: () => import("@/views/data-collect/Form-dynamic-design.vue"),
  {
    path: "/data-collect/:id",
    name: "data-collect",
    component: () => import("@/views/data-collect/Collect.vue"),
    props: {},
    meta: {
      //{ acl
      resource: "data-collect",
      action: "read",
      //} 
    },
  },
];

// export
export default routesFormated;
