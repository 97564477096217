import mock from "@/@fake-db/mock";
/* eslint-disable global-require */
const data = {
  congratulations: {
    name: "Dev Kama",
    saleToday: "48900",
  },
  statisticsItems: [
    {
      icon: "TrendingUpIcon",
      color: "light-primary",
      title: "230k",
      subtitle: "Achats",
      customClass: "mb-2 mb-xl-0",
    },
    {
      icon: "UserIcon",
      color: "light-info",
      title: "8.549k",
      subtitle: "Béneficiaires",
      customClass: "mb-2 mb-xl-0",
    },
    {
      icon: "BoxIcon",
      color: "light-danger",
      title: "1.423k",
      subtitle: "Produits",
      customClass: "mb-2 mb-sm-0",
    },
    {
      icon: "DollarSignIcon",
      color: "light-success",
      title: "9745$",
      subtitle: "Revenue",
      customClass: "",
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: "2020",
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [0, 20, 5, 30, 15, 45],
      },
    ],
  },
  earningsChart: {
    series: [53, 16, 31],
  },
  revenue: {
    years: ["2020", "2019", "2018"],
    price: "25,852",
    budget: "56,800",
    revenueReport: {
      series: [
        {
          name: "Financements",
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: "Dépenses",
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    {
      avatarImg: require("@/assets/images/icons/toolbox.svg"),
      title: "Dixons",
      subtitle: "gomaDev@github.io",
      avatarIcon: "MonitorIcon",
      avatarColor: "light-primary",
      avatarTitle: "Technology",
      viewTitle: "23.4k",
      viewsub: "en 24 heures",
      revenue: "891.2",
      sales: "68",
      loss: true,
    },
    {
      avatarImg: require("@/assets/images/icons/parachute.svg"),
      title: "Viles",
      subtitle: "test@admin.dfj.cd",
      avatarIcon: "CoffeeIcon",
      avatarColor: "light-success",
      avatarTitle: "Grocery",
      viewTitle: "78k",
      viewsub: "en 3 jours",
      revenue: "668.51",
      sales: "97",
      loss: false,
    },
    {
      avatarImg: require("@/assets/images/icons/brush.svg"),
      title: "Territoires",
      subtitle: "test2@is.gov.cd",
      avatarIcon: "WatchIcon",
      avatarColor: "light-warning",
      avatarTitle: "Fashion",
      viewTitle: "162",
      viewsub: "en 5 jours",
      revenue: "522.29",
      sales: "62",
      loss: false,
    },
    {
      avatarImg: require("@/assets/images/icons/star.svg"),
      title: "Parquet",
      subtitle: "info@gdeinstance.cd",
      avatarIcon: "MonitorIcon",
      avatarColor: "light-primary",
      avatarTitle: "Technology",
      viewTitle: "214",
      viewsub: "en 24 heures",
      revenue: "291.01",
      sales: "88",
      loss: false,
    },
    {
      avatarImg: require("@/assets/images/icons/book.svg"),
      title: "Dons",
      subtitle: "pudais@jife.com",
      avatarIcon: "CoffeeIcon",
      avatarColor: "light-success",
      avatarTitle: "Grocery",
      viewTitle: "208",
      viewsub: "en 1 semaine",
      revenue: "783.93",
      sales: "16",
      loss: true,
    },
    {
      avatarImg: require("@/assets/images/icons/rocket.svg"),
      title: "Marché",
      subtitle: "bipri@cawiw.com",
      avatarIcon: "WatchIcon",
      avatarColor: "light-warning",
      avatarTitle: "Fashion",
      viewTitle: "990",
      viewsub: "en 1 mois",
      revenue: "780.05",
      sales: "78",
      loss: false,
    },
    {
      avatarImg: require("@/assets/images/icons/speaker.svg"),
      title: "Avances",
      subtitle: "luk@izug.cd",
      avatarIcon: "WatchIcon",
      avatarColor: "light-warning",
      avatarTitle: "Fashion",
      viewTitle: "12.9k",
      viewsub: "en 12 heures",
      revenue: "531.49",
      sales: "42",
      loss: false,
    },
  ],
  meetup: {
    mediaData: [
      {
        avatar: "CalendarIcon",
        title: "Elikar, 25 Mai 2022",
        subtitle: "10:00 à 16h:30",
      },
      {
        avatar: "MapPinIcon",
        title: "Ibrahim Park",
        subtitle: "Nord Kivu, ville de Goma",
      },
    ],
    avatars: [
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
        fullName: "Kambere Isse Mwami",
      },
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
        fullName: "Julen Paluku",
      },
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
        fullName: "Georges Mutaka",
      },
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
        fullName: "Iragi Igwe",
      },
      {
        avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
        fullName: "Jeanne Bakwanga",
      },
    ],
  },
  goalOverview: {
    completed: "786,617",
    inProgress: "13,561",
    series: [83],
  },
  transactionData: [
    {
      mode: "Portefeuille",
      types: "Achat Carburant",
      avatar: "PocketIcon",
      avatarVariant: "light-primary",
      payment: "-$74",
      deduction: true,
    },
    {
      mode: "Transfer Banques",
      types: "Payement Loyer",
      avatar: "CheckIcon",
      avatarVariant: "light-success",
      payment: "-$2980",
      deduction: false,
    },
    {
      mode: "Paypal",
      types: "Renouvellement Hebergement",
      avatar: "DollarSignIcon",
      avatarVariant: "light-danger",
      payment: "+$180",
      deduction: false,
    },
    {
      mode: "Mastercard",
      types: "Achat NFCs",
      avatar: "CreditCardIcon",
      avatarVariant: "light-warning",
      payment: "-$23",
      deduction: true,
    },
    {
      mode: "Monnaie Mobile",
      types: "Retour",
      avatar: "TrendingUpIcon",
      avatarVariant: "light-info",
      payment: "+$98",
      deduction: false,
    },
  ],
};
/* eslint-disable global-require */
mock.onGet("/ecommerce/data").reply(() => [200, data]);
