////////////////////////////////////

var formatedMenus = [];
/* formatedMenus.push({
  header: "Gestion Globale",
  action: "read",
  resource: "ACL",
});

// add separator
formatedMenus.push({
  header: "Autres Outils",
  action: "read",
  resource: "ACL",
}); */

// export
export default formatedMenus;
